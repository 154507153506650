<template>
  <div>
    <b-row>
      <b-col md="12" v-if="compressImage">
        <b-form-checkbox v-model="compressor" 
                          switch 
                          size="sm" 
                          class="pull-left">
          Comprimir Imágenes
        </b-form-checkbox>            
      </b-col>
      <b-col md="12">
        <vue-upload-multiple-image
          @before-remove="remove"
          @upload-success="upload"
          @edit-image="edit"
          :dataImages="dataImages"
          :showPrimary="showPrimaryImage"
          :multiple="multipleImage"
          :maxImage="maxImage"
          dragText="Arrastrar imágenes"
          browseText="o Seleccionar"
          markIsPrimaryText="Predeterminar"
          primaryText="Por defecto"
          popupText="Esta imagen se mostrará como predeterminada"
          :idUpload="'upload' + typeImage" 
          :idEdit="'edit' + typeImage"
          dropText="Suelta tus archivos aquí ...">
        </vue-upload-multiple-image> 
      </b-col>
    </b-row>    
  </div>
</template>

<script>
  import VueUploadMultipleImage from 'vue-upload-multiple-image' 
  import Compressor from 'compressorjs';
  export default {
    components: {
      VueUploadMultipleImage,
    },  
    props: {
      multipleImage: {
        type: Boolean,
        default: false
      }, 
      showPrimaryImage: {
        type: Boolean,
        default: false
      },
      typeImage: {
        type: String,
        required: true,
      },
      images: {
        type: Array,
        required: true,        
      },
      maxImage: {
        type: Number,
        required: false,
        default: 8,
      },
      compressImage: {
        type: Boolean,
        default: false,
      }      
    },
    data() {
      return {
        compressor: true,
        compressorQuality: 0.6,
      }
    },
    computed: {
      dataImages: {     
        get: function () {
          return this.images;
        },
        set: function (value) {
          this.$emit('load-image', value)
        }                 
      }
    },
    methods: {      
      remove (index, done, fileList) {
        var r = confirm("¿Eliminar Imagen?")
        if (r == true) {
          done()
        }
        this.dataImages = fileList        
      },
      upload (formData, index, fileList) {
        this.dataImages = fileList

        if(this.compressor && this.compressImage) {
          fileList.forEach(element => {
            
            if(element.name != undefined) {
              var base64Data = this.dataURItoBlob(element.path)             
              new Compressor(base64Data, {
                quality: this.compressorQuality,
                strict: true,
                success(result) {
                    var reader = new FileReader();
                    reader.readAsDataURL(result); 
                    reader.onloadend = function() {
                        var base64data = reader.result;                

                        element.path = base64data 
                        this.dataImages = fileList        
                    }            
                },
              });
            }

          });       
        }   
      },
      edit (formData, index, fileList) {
        this.dataImages = fileList

        if(this.compressor && this.compressImage) {
          fileList.forEach(element => {
            
            if(element.name != undefined) {
              var base64Data = this.dataURItoBlob(element.path)             
              new Compressor(base64Data, {
                quality: compressorQuality,
                strict: true,
                success(result) {
                    var reader = new FileReader();
                    reader.readAsDataURL(result); 
                    reader.onloadend = function() {
                        var base64data = reader.result;                

                        element.path = base64data 
                        this.dataImages = fileList        
                    }            
                },
              });            
            }
            
          });
        }          
      },
      dataURItoBlob(dataURI) {
          // convert base64/URLEncoded data component to raw binary data held in a string
          var byteString;
          if (dataURI.split(',')[0].indexOf('base64') >= 0)
              byteString = atob(dataURI.split(',')[1]);
          else
              byteString = unescape(dataURI.split(',')[1]);

          // separate out the mime component
          var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

          // write the bytes of the string to a typed array
          var ia = new Uint8Array(byteString.length);
          for (var i = 0; i < byteString.length; i++) {
              ia[i] = byteString.charCodeAt(i);
          }

          return new Blob([ia], {type:mimeString});
      },           
    }    
  }      
</script>